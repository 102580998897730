import { isArray } from 'lodash-es';

import { FilterField, FilterFieldField, FilterFieldType, FilterGroup } from '@/modules/api/shared-contracts';
import { FilterFieldDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { logger } from '@/modules/monitoring';
import { ControlModule } from '@/store/modules/control.module';
import { getFlightNumberRangeQueryString } from '@/utils';

export class QueryTransformService {
  public static transformToParams(filters: (FilterFieldDefinition | FilterGroup)[] = []): { [key: string]: string } {
    const urlParams: { [key: string]: string } = {};

    filters.forEach((filterField: FilterFieldDefinition | FilterGroup) => {
      if ('fields' in filterField) {
        if (filterField.fields.some((field) => (field as unknown as FilterField).field === FilterFieldField.flightNumber)) {
          urlParams[FilterFieldField.flightNumber] = getFlightNumberRangeQueryString(filterField.fields);
          urlParams[`${FilterFieldField.flightNumber}Type`] = FilterFieldType.equal;
        }
      } else if (
        filterField.type === FilterFieldType.none ||
        (isArray(filterField.value) ? filterField.value.length > 0 : filterField.value !== null && filterField.value !== undefined)
      ) {
        const fieldDefinition: FilterFieldDefinition | undefined = filterField.transformToParams
          ? filterField
          : ControlModule.filters.find((ffd) => {
              // Unfortunately there are a few exceptions left where we need to plot the filterField.field on a filter with a slightly different name
              if (filterField.field === FilterFieldField.departureDate) {
                return ffd.field === FilterFieldField.departureDateRange;
              }

              if (filterField.field === FilterFieldField.flightNumber) {
                return ffd.field === FilterFieldField.flightNumberRange || ffd.field === FilterFieldField.flightNumber;
              }

              return ffd.field === filterField.field;
            });

        if (!fieldDefinition) {
          logger.error(new Error(filterField.field + ' is not correctly defined'));
          return;
        }

        urlParams[fieldDefinition.field] = fieldDefinition.transformToParams
          ? fieldDefinition.transformToParams(filterField.value, filterField.type, urlParams)
          : filterField.value;

        // If the filter type is not set, set the filter type based upon the definition
        if (filterField.type) {
          urlParams[`${fieldDefinition.field}Type`] = filterField.type;
        } else if (fieldDefinition.type) {
          urlParams[`${fieldDefinition.field}Type`] = fieldDefinition.type;
        }
      }
    });

    return urlParams;
  }
}
